<template>
 <el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <div class="heading">
      <h1 v-if="!listingId">Add Listing</h1>
      <h1 v-if="listingId">Edit Listing</h1></div>
  </el-col>
  <el-col :xs="6" :sm="6" :md="6" :lg="12" :xl="12">
    <div class="topsearch">
      <el-button type="primary" @click="listings()">Back On Listings</el-button>
    </div>
  </el-col>
 </el-row>
<el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
  <div v-if="showAlertAdded">
    <el-alert
      title="Listing Added Successfully"
      type="success"
      effect="dark"
      v-if="!listingId"
    />
    <el-alert
        title="Listing Updated Successfully"
        type="success"
        effect="dark"
        v-if="listingId"
    />
  </div>
  <div v-if="showAlertSlugexit">
    <el-alert
      title="The slug has already been taken."
      type="error"
      effect="dark"
    />
  </div>
  </el-col>
</el-row>
    <el-form
     ref="ruleForm"
      :model="data"
      status-icon
      :rules="rules"
      class=" login-form"
      :label-position="labelPosition" 
    >
    <el-tabs 
        type="border-card"
      >
      <el-tab-pane 
          label="Listing Main Information"
        > 
	<div class="panel panel-default">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
              <el-form-item 
                label="Listing Type" 
                prop="listing_type"
              >
                <el-select 
                  v-model="data.listing_type"
                >
                  <el-option 
                    value="1/2 Duplex" 
                    label="1/2 Duplex"
                  />
                  <el-option 
                    value="Apartment" 
                    label="Apartment"
                  />
                  <el-option 
                    value="Apartment/Condo" 
                    label="Apartment/Condo"
                  />
                  <el-option 
                    value="Apartment Unit" 
                    label="Apartment Unit"
                  />
                  <el-option 
                    value="Duplex" 
                    label="Duplex"
                  />
                  <el-option 
                    value="Fourplex" 
                    label="Fourplex"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Status" 
                prop="status"
              >
                <el-select 
                  v-model="data.status"
                >
                  <el-option 
                    value="Active" 
                    label="Active"
                  />
                  <el-option 
                    value="Draft" 
                    label="Draft"
                  />
                  <el-option 
                    value="Expired" 
                    label="Expired"
                  />
                  <el-option 
                    alue="Hold all Action" 
                    label="Hold all Action"
                  />
                  <el-option 
                    value="Sold" 
                    label="Sold"
                  />
                  <el-option 
                    value="Under Contract" 
                    label="Under Contract"
                  />
                  <el-option 
                    value="Assignments" 
                    label="Assignments"
                  />
                  <el-option 
                    value="Terminated" 
                    label="Terminated"
                  />
                  <el-option 
                    value="Inactive" 
                    label="Inactive"
                  />
                  <el-option 
                    value="Secret" 
                    label="Secret"
                  />
                  <el-option 
                    value="Pending" 
                    label="Pending"
                  />
                  <el-option 
                    value="Rented" 
                    label="Rented"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="Building"
                prop="building_id"
              >
                <el-select v-model="data.building_name" placeholder="Select Building">
                <el-option
                v-for="item in buildings"
                :key="item.value"
                :value="item.value"
                :label="item.label"
                ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Community" 
                prop="community_id"
              >
                <el-select v-model="data.community_name" placeholder="Select Community">
                <el-option
                v-for="item in communites"
                :key="item.value"
                :value="item.value"
                :label="item.label"
                ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="MLS Listing ID" 
                prop="mls_listing_id"
              >
                <el-input 
                  v-model="data.mls_listing_id" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Sold Date" 
                prop="sold_date"
              >
                <el-date-picker 
                  v-model="data.sold_date" 
                  type="date" 
                  placeholder="Pick a day" 
                  style="width:100%"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Ribbon" 
                prop="ribbon"
              >
              <el-select v-model="data.ribbon">
              <el-option 
              v-for="item in array_ribbon" 
              :key="item.label"
              :value="item.value"
              :label="item.label"           
              >
              {{ item.label }}
              </el-option>
            </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Available Date" 
                prop="available_date"
              >
                <el-date-picker 
                  v-model="data.available_date" 
                  type="date" 
                  placeholder="Pick a day" 
                  style="width:100%"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item 
                label="Assignment" 
                prop="assignment"
              >
                <el-checkbox 
                  v-model="data.assignment"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item 
                label="Team Listing" 
                prop="teamlisting"
              >
                <el-checkbox 
                  v-model="data.teamlisting"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item 
                label="Featured Listing" 
                prop="featuredlisting"
              >
                <el-checkbox 
                  v-model="data.featuredlisting"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item 
                label="Is SHOW HOME?" 
                prop="is_show_home"
              >
                <el-checkbox 
                  v-model="data.is_show_home"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item 
                label="Is READY TO MOVE?" 
                prop="is_ready_to_move"
              >
                <el-checkbox 
                  v-model="data.is_ready_to_move"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item 
                label="Listing Of The Month" 
                prop="listingofthemonth"
              >
                <el-checkbox 
                  v-model="data.listingofthemonth"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item 
                label="Offer Pending" 
                prop="offer_pending"
              >
                <el-checkbox 
                  v-model="data.offer_pending"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item 
                label="Is Exclusive?" 
                prop="is_exclusive"
              >
                <el-checkbox 
                  v-model="data.is_exclusive"
                />
              </el-form-item>
            </el-col>
          </el-row>
	</div>
        </el-tab-pane>
        <el-tab-pane 
          label="Listing Address Information"
        >
	<div class="panel panel-default">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Suite Number" 
                prop="suite_no"
              >
                <el-input 
                  v-model="data.suite_no" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Street Number" 
                prop="street_number"
              >
                <el-input 
                  v-model="data.street_number" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item> 
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Street Direction" 
                prop="street_dir"
              >
                <el-input 
                  v-model="data.street_dir" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Street Name" 
                prop="street_name"
              >
                <el-input 
                  v-model="data.street_name" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item> 
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Street Type" 
                prop="street_type"
              >
                <el-input 
                  v-model="data.street_type" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Country" 
                prop="country"
              >
                <el-select v-model="data.country_name" placeholder="Select Country">
                <el-option
                v-for="item in countries"
                :key="item.value"
                :value="item.value"
                :label="item.label"
                @click="getStates(item.value)"
                ></el-option>
                </el-select>
              </el-form-item> 
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Area" 
                prop="state"
              >
                <el-select v-model="data.state_name"  placeholder="Select Region / State">
                  <el-option              
                  v-for="item in states"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  @click="getCities(item.value)"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Sub Area" 
                prop="city"
              >
              <el-select v-model="data.city_name" placeholder="City">
              <el-option              
              v-for="item in cities"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              />
              </el-select>
              </el-form-item> 
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Postal/Zip Code" 
                prop="postalcode"
              >
                <el-input 
                  v-model="data.postalcode" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Lat" 
                prop="lat"
              >
                <el-input 
                  v-model="data.lat" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item> 
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Lng" 
                prop="lng"
              >
                <el-input 
                  v-model="data.lng" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
	</div>
           </el-tab-pane>
           <el-tab-pane 
          label="General Information"
        >
	<div class="panel panel-default">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="List Price" 
                prop="list_price"
              >
                <el-input 
                  v-model="data.list_price" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Request for Price" 
                prop="price_on_request"
              >
              <el-radio-group v-model="data.price_on_request">
                  <el-radio :label="1">Yes</el-radio>
                  <el-radio :label="2">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Rental Duration" 
                prop="rental_duration"
              >

              <el-select v-model="data.rental_duration">
              <el-option 
              v-for="item in array_rental_duration" 
              :key="item.label"
              :value="item.value"
              :label="item.label"           
              >
              {{ item.label }}
              </el-option>
            </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Tax Amount" 
                prop="tax_amount"
              >
                <el-input 
                  v-model="data.tax_amount" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Expires" 
                prop="expires_date"
              >
                <el-date-picker 
                  v-model="data.expires_date" 
                  type="date" 
                  placeholder="Pick a day" 
                  style="width:100%"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Completion Date" 
                prop="completion_date"
              >
                <el-date-picker 
                  v-model="data.completion_date" 
                  type="date" 
                  placeholder="Pick a day"
                  style="width:100%"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="List Date" 
                prop="list_date"
              >
                <el-date-picker 
                  v-model="data.list_date" 
                  type="date" 
                  placeholder="Pick a day" 
                  style="width:100%"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Finished Levels" 
                prop="finished_levels"
              >
                <el-input 
                  v-model="data.finished_levels" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Lotsize" 
                prop="lot_size"
              >
                <el-input 
                  v-model="data.lot_size" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="House Size" 
                prop="SizeInterior"
              >
                <el-input 
                  v-model="data.SizeInterior" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Bedrooms" 
                prop="bedrooms"
              >
                <el-input 
                  v-model="data.bedrooms" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Bathrooms" 
                prop="bathrooms"
              >
                <el-input 
                  v-model="data.bathrooms" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Partial Bathroom" 
                prop="bathroom_partial"
              >
                <el-input 
                  v-model="data.bathroom_partial" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Total Rooms" 
                prop="total_rooms"
              >
                <el-input 
                  v-model="data.total_rooms" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Flex Rooms" 
                prop="flex_rooms"
              >
                <el-input 
                  v-model="data.flex_rooms" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Kitchens" 
                prop="kitchens"
              >
                <el-input 
                  v-model="data.kitchens" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Year Built" 
                prop="year_built"
              >
                <el-input 
                  v-model="data.year_built" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Exposure" 
                prop="exposure"
              >
                <el-input 
                  v-model="data.exposure" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Frontage" 
                prop="frontage"
              >
                <el-input 
                  v-model="data.frontage" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Depth" 
                prop="depth"
              >
                <el-input 
                  v-model="data.depth" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Home Style" 
                prop="home_style"
              >
                <el-input 
                  v-model="data.home_style" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Outdoor Area" 
                prop="outdoor_area"
              >
                <el-input 
                  v-model="data.outdoor_area" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="By-Law Restrictions" 
                prop="bylaw_restrictions"
              >
                <el-input 
                  v-model="data.bylaw_restrictions" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Legal Description" 
                prop="legal_description"
              >
                <el-input 
                  v-model="data.legal_description" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Age Description" 
                prop="age_description"
              >
                <el-input 
                  v-model="data.age_description" 
                  type="textarea" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Sale Info" 
                prop="sale_info"
              >
                <el-input 
                  v-model="data.sale_info" 
                  type="textarea" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Show Homes" 
                prop="show_homes"
              >
                <el-input 
                  v-model="data.show_homes" 
                  type="textarea" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>

           <el-row :gutter="20">
             <el-col>
               <el-fieldset>Media Info</el-fieldset>
             </el-col>
           </el-row>
           <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Virtual Tour Directory" 
                prop="virtual_tour_url"
              >
                <el-input 
                  v-model="data.virtual_tour_url" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Vimeo" 
                prop="vimeo"
              >
                <el-input 
                  v-model="data.vimeo" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
           <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Youtube Url" 
                prop="youtube_url"
              >
                <el-input 
                  v-model="data.youtube_url" 
                  type="text" 
                  autocomplete="off"
                />
                <span style="float:left;">Please enter Youtube video url</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Display Video Thumb" 
                prop="video_thumb"
              >
                 <el-checkbox
                  v-model="data.video_thumb" 
                />
              </el-form-item>
            </el-col>
          </el-row>
           <el-row :gutter="20">
             <el-col>
               <el-fieldset>Floor Info</el-fieldset>
             </el-col>
           </el-row>
           <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Finished Floor Area" 
                prop="finished_floor_area"
              >
                <el-input 
                  v-model="data.finished_floor_area" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Floor Area" 
                prop="floor_area"
              >
                <el-input 
                  v-model="data.floor_area" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item 
                label="Flooring"
              >
                <el-form-item prop="flooring">
                  <el-checkbox-group v-model="data.flooring">
                  <el-checkbox              
                  v-for="floorings in array_flooring"
                  :key="floorings.value"
                  :label="floorings.value"
                  :value="floorings.value"
                  >
                  {{floorings.label}}
                  </el-checkbox>
                  </el-checkbox-group>
                  </el-form-item> 
              </el-form-item>
            </el-col>
          </el-row>
	</div>
 </el-tab-pane>
 <el-tab-pane 
          label="Additional Information"
        >
	<div class="panel panel-default">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Construction" 
                prop="construction"
              >
                <el-input 
                  v-model="data.construction" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Foundation" 
                prop="foundation"
              >

              <el-checkbox-group v-model="data.foundation">
                  <el-checkbox              
                  v-for="foundation in array_foundation"
                  :key="foundation.value"
                  :label="foundation.value"
                  :value="foundation.value"
                  >
                  {{foundation.label}}
                  </el-checkbox>
                  </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Roof" 
                prop="roof"
              >
                <el-input 
                  v-model="data.roof" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Water Supply" 
                prop="water_supply"
              >
                <el-input 
                  v-model="data.water_supply" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Exterior Finish" 
                prop="exterior_finish"
              >
                <el-input 
                   v-model="data.exterior_finish"
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Title To Land" 
                prop="title_to_land"
              >
                <el-input 
                  v-model="data.title_to_land"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Sewer" 
                prop="sewer"
              >
                <el-input 
                  v-model="data.sewer" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Maintenance Fee" 
                prop="maintenance_fee"
              >
                <el-input 
                  v-model="data.maintenance_fee"
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Units in Development" 
                prop="units_in_development"
              >
                <el-input 
                  v-model="data.units_in_development"
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Occupancy" 
                prop="occupancy"
              >
                <el-input 
                  v-model="data.occupancy"
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="School Info" 
                prop="school_info"
              >
                <el-input 
                  v-model="data.school_info"
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="School Tax" 
                prop="school_tax"
              >
                <el-input 
                   v-model="data.school_tax"
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Mortgage info" 
                prop="mortgage_info"
              >
                <el-input 
                  v-model="data.mortgage_info" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Listing Note" 
                prop="listing_note"
              >
                <el-input 
                  v-model="data.listing_note"  
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item 
                label="Goods Included"
              >
                <el-form-item prop="listing_goods">
                  <el-checkbox-group v-model="data.listing_goods">
                  <el-checkbox              
                  v-for="listing_good in array_listing_goods"
                  :key="listing_good.value"
                  :label="listing_good.value"
                  :value="listing_good.value"
                  >
                  {{listing_good.label}}
                  </el-checkbox>
                  </el-checkbox-group>
                  </el-form-item> 
              </el-form-item>
            </el-col>
          </el-row>
           <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item 
                label="Site Influences"
              >
                <el-form-item prop="site_influences">
                  <el-checkbox-group v-model="data.site_influences">
                  <el-checkbox              
                  v-for="influences in array_site_influences"
                  :key="influences.value"
                  :label="influences.value"
                  :value="influences.value"
                  >
                  {{influences.label}}
                  </el-checkbox>
                  </el-checkbox-group>
                  </el-form-item> 
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
             <el-col>
               <el-fieldset>Basement</el-fieldset>
             </el-col>
           </el-row>
           <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item 
                label="Basement Development" 
              >
              <el-checkbox-group v-model="data.basement_dev">
                  <el-checkbox              
                  v-for="basementdev in array_basement_dev"
                  :key="basementdev.value"
                  :label="basementdev.value"
                  :value="basementdev.value"
                  >
                  {{basementdev.label}}
                  </el-checkbox>
                  </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item 
                label="Basement type" 
              >
              <el-checkbox-group v-model="data.basement_type">
                  <el-checkbox              
                  v-for="basementtype in array_basement_type"
                  :key="basementtype.value"
                  :label="basementtype.value"
                  :value="basementtype.value"
                  >
                  {{basementtype.label}}
                  </el-checkbox>
                  </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
           <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Basement" 
              >
              <el-input 
                  v-model="data.basement"  
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
             <el-col>
               <el-fieldset>Fire Detail</el-fieldset>
             </el-col>
           </el-row>
             <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item 
                  label="Fireplaces" 
                >
                <el-input 
                    v-model="data.fireplaces"  
                    type="text" 
                    autocomplete="off"
                  />
                </el-form-item>
              </el-col>
               <el-col :span="12">
              <el-form-item 
                label="Fireplace Fuel" 
              >
              <el-input 
                  v-model="data.fireplace_fuel"  
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item 
                  label="Fireplace Details" 
                >
                <el-input 
                    v-model="data.fireplace_details"  
                    type="text" 
                    autocomplete="off"
                  />
                </el-form-item>
              </el-col>
               <el-col :span="12">
              <el-form-item 
                label="Heating" 
              >
              <el-input 
                  v-model="data.heating"  
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
           <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item 
                  label="Heating Fuel" 
                >
                <el-input 
                    v-model="data.heating_fuel"  
                    type="text" 
                    autocomplete="off"
                  />
                </el-form-item>
              </el-col>
          </el-row>
          <el-row :gutter="20">
             <el-col>
               <el-fieldset>Parking</el-fieldset>
             </el-col>
           </el-row>
             <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item 
                  label="Parking Reg" 
                >

                 <el-select v-model="data.parking_reg">
                  <el-option 
                  v-for="item in array_parking_reg" 
                  :key="item.label"
                  :value="item.value"
                  :label="item.label"           
                  >
                  {{ item.label }}
                  </el-option>
                </el-select>
                </el-form-item>
              </el-col>
               <el-col :span="12">
              <el-form-item 
                label="Parking Area Covered" 
              >
              <el-input 
                  v-model="data.parking_area_covered"  
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
	</div>
 </el-tab-pane>
  <el-tab-pane 
  label="Links"
  >
  <div class="panel panel-default">
      <el-row :gutter="20">
        <el-col :span="12" class="padding-t-b-20">
        <div class="align-left">
        <el-button  size="large" type="primary" icon="el-icon-plus" @click="addlinks" >
        </el-button>
        
        </div>
        </el-col>
        </el-row>


  <div v-for="(link, index_link) in array_link" :key="index_link">
     
        <el-row :gutter="20" class="row-border-top">
         <el-col :span="7">
          <el-form-item 
          label="Url"
          prop="link_url"
          >
            <el-input v-model="link.link_url"  
            :id="'link_url'+index_link"
            type="text"/>
        </el-form-item>
        </el-col>
        <el-col :span="7">
        <el-form-item 
        label="Link Title" 
        prop="link_title"
        >
          <el-input 
          v-model="link.link_title" 
          :id="'link_title'+index_link"
          type="text" 
          />
          </el-form-item>
        </el-col>
        <el-col :span="7">
        <el-form-item 
        label="Link Description"
        prop="link_description"
        >
          <el-input
          v-model="link.link_description" 
          :id="'link_description'+index_link"
          type="text"
          />
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item  label="Remove">
        <el-button  size="large" type="primary" icon="el-icon-minus" @click="deletelink(index)"></el-button>
          </el-form-item>
        </el-col>
        </el-row>
      </div>

  </div>
  </el-tab-pane>
        <el-tab-pane label="Amenities/Featuresn">
        <div class="panel panel-default">
        <el-row :gutter="20">
          <el-col :xs="48" :sm="48" :md="48" :lg="48" :xl="48">

            <el-form-item prop="amenities">
              <el-checkbox-group v-model="data.amenities">
              <el-checkbox              
              v-for="amenity in arrayamenities"
              :key="amenity.value"
              :label="amenity.value"
              :value="amenity.value"
              >
              {{amenity.label}}
              </el-checkbox>
              </el-checkbox-group>
              </el-form-item> 
          </el-col>
        </el-row>
        </div>
        </el-tab-pane>
        <el-tab-pane 
          label="Internal Notes"
        >
	<div class="panel panel-default">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item 
                label="Internal Notes" 
                prop="internal_notes"
              >
                <el-input 
                  v-model="data.internal_notes" 
                  type="textarea" 
                  autocomplete="off" 
                  rows="7"
                />
              </el-form-item>
            </el-col>
          </el-row>
	</div>
        </el-tab-pane>
        <el-tab-pane 
          label="Description"
        >
	<div class="panel panel-default">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item 
                label="Listing Description" 
                prop="listing_description"
              >
                <el-input
                  v-model="data.listing_description" 
                  type="textarea"  
                  rows="6"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item 
                label="Realtor Listing Description" 
                prop="custom_listing_description"
              >
                <el-input 
                  v-model="data.custom_listing_description" 
                  type="textarea"  
                  rows="6"
                />
              </el-form-item>
            </el-col>
          </el-row>
	</div>
        </el-tab-pane><el-tab-pane 
          label="Photos"
        > 
	<div class="panel panel-default">
          <el-form-item 
            label="Logo" 
            prop="logo_image"
          >
            <el-upload
                ref="upload"
              class="upload-demo"
              drag
              :action="imageUploadUrl"
              list-type="picture"
              :headers="imageUploadHeaders"
              name="file"
              :limit="1"
              :on-exceed="handleExceed"
              :file-list="fileList"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-success="handleImageUploadSuccess"
              :on-error="handleImageUploadError"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em><br/>
				JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M
              </div>
            </el-upload>
          </el-form-item>
		<el-form-item 
            label="More Images" 
            prop="more_image"
          >
            <el-upload
              class="upload-demo"
              drag
              :action="imageUploadUrl"
              list-type="picture"
              :headers="imageUploadHeaders"
              name="file"
              :file-list="fileListMoreImages"
              :limit="20"
              multiple
              :on-preview="handlePreviewMoreImages"
              :on-remove="handleRemoveMoreImages"
              :on-success="handleImageUploadSuccessMoreImages"
              :on-error="handleImageUploadErrorMoreImages"
			  id="moreimg-layout"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em><br/>JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M
              </div>
            </el-upload>
          </el-form-item>
          <el-row :gutter="20">
            <el-col :span="6"><el-form-item 
                label="Photo Title"
              /></el-col>
            <el-col :span="18">              
                <el-input 
                  v-model="data.logo_image_title" 
                  type="text" 
                  autocomplete="off"
                />
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item 
                label="Photo Description" 
              />
            </el-col>
               <el-col :span="18">
              <el-input 
                v-model="data.logo_image_description" 
                type="text" 
                autocomplete="off"
              />
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
             <el-form-item 
                label="Listing Banner Text" 
                prop="banner_text"
              />
            </el-col>
            <el-col :span="18">  
              <el-select v-model="data.banner_text" placeholder="Select Building">
                <el-option
                v-for="item in array_banner_text"
                :key="item.value"
                :value="item.value"
                :label="item.label"
                ></el-option>
                </el-select>
            </el-col>
          </el-row>
	</div>
        </el-tab-pane>
        <el-tab-pane 
          label="Seo Info"
        >
	<div class="panel panel-default">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Meta Title" 
                prop="meta_title"
              >
                <el-input 
                  v-model="data.meta_title" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="Meta Description" 
                prop="meta_description"
              >
                <el-input 
                  v-model="data.meta_description" 
                  type="textarea" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Meta Keyword" 
                prop="meta_keyword"
              >
                <el-input 
                  v-model="data.meta_keyword" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Slug" 
                prop="slug"
              >
                <el-input 
                  v-model="data.slug" 
                  type="textarea" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Old Slug" 
                prop="old_slug"
              >
                <el-input 
                  v-model="data.old_slug" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
	</div>
        </el-tab-pane>
        <el-tab-pane 
          label="Social"
        >
	<div class="panel panel-default">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Social Post Title" 
                prop="social_posttitle"
              >
                <el-input 
                  v-model="data.social_posttitle" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Social Hash Tags" 
                prop="social_hashtag"
              >
                <el-input 
                  v-model="data.social_hashtag" 
                  type="textarea" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Post Listing" 
                prop="post_listing">
                <el-radio-group v-model="data.post_listing">
                  <el-radio :label="1">Yes</el-radio>
                  <el-radio :label="2">No</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
	</div>
        </el-tab-pane>
        <el-tab-pane 
        label="Open House"
        >
        <div class="panel panel-default">
        <el-row :gutter="20">
        <el-col :span="12" class="padding-t-b-20">
        <div class="align-left">
        <el-button  size="large" type="primary" icon="el-icon-plus" @click="addopenhouse" >
        </el-button>
        
        </div>
        </el-col>
        </el-row>
      <div v-for="(data, index_open) in array_openhouse" :key="index_open">
     
        <el-row :gutter="20" class="row-border-top">
         <el-col :span="7">
          <el-form-item 
          label="Date"
          prop="open_house_date"
          >
            <el-date-picker v-model="data.open_house_date"  
            autocomplete="off"
            :id="'open_house_date_'+index_open"
            value-format="YYYY-MM-DD"
            type="date" placeholder="Pick a date"/>
        </el-form-item>
        </el-col>
        <el-col :span="7">
        <el-form-item 
        label="Start Time" 
        prop="open_house_starttime"
        >
          <el-date-picker 
          v-model="data.open_house_starttime" 
          :id="'open_house_starttime_'+index_open"
          value-format="YYYY-MM-DD hh:mm"
          type="datetime" placeholder="Pick a starttime"
          autocomplete="off"
          />
          </el-form-item>
        </el-col>
        <el-col :span="7">
        <el-form-item 
        label="End Time"
        prop="open_house_endtime"
        >
          <el-date-picker 
          v-model="data.open_house_endtime" 
          :id="'open_house_endtime_'+index_open"
          value-format="YYYY-MM-DD hh:mm"
          type="datetime" 
          placeholder="Select date and endtime"
          autocomplete="off"
          />
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item  label="Remove">
        <el-button  size="large" type="primary" icon="el-icon-minus" @click="deleteopenhouse(index)"></el-button>
          </el-form-item>
        </el-col>
        </el-row>
      </div>
      </div>
        </el-tab-pane>
        <el-tab-pane 
          label="Room Information"
          class="line-height-5"
        >
         <div class="panel panel-default">
        <el-row :gutter="20">
        <el-col :span="12" class="padding-t-b-20">
        <div class="align-left">
        <el-button  size="large" type="primary" icon="el-icon-plus" @click="addrooms" >
        </el-button>       
        </div>
        </el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="6">
        <div class="append-col-name">Floor</div>
        </el-col>
        <el-col :span="6">
        <div  class="append-col-name">Type</div>
        </el-col>
         <el-col :span="6">
        <div  class="append-col-name">Dimensions</div>
        </el-col>
        <el-col :span="4">
        <div  class="append-col-name">Other</div>
        </el-col>
        <el-col :span="2">
        <div  class="append-col-name">Action</div>
        </el-col>
        </el-row>  

      <div v-for="(room, index_room) in array_room" :key="index_room">
     
        <el-row :gutter="20" class="row-border-top">
         <el-col :span="6">
          <el-form-item 
          label="&nbsp;"
          prop="room_floor"
          >
          <el-select v-model="room.room_floor"
          :id="'room_floor'+index_room"
        >
          <el-option 
          v-for="item in array_roomfloor" 
          :key="item.label"
          :value="item.value"
          :label="item.label"           
          >
          {{ item.label }}
          </el-option>
        </el-select>
        </el-form-item>
        </el-col>
        <el-col :span="6">
        <el-form-item 
        label="&nbsp;" 
        prop="room_type"
        >

        <el-select v-model="room.room_type"
          :id="'room_type'+index_room"
        >
          <el-option 
          v-for="item in array_roomtype" 
          :key="item.label"
          :value="item.value"
          :label="item.label"           
          >
          {{ item.label }}
          </el-option>
        </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="3">
        <el-form-item 
        label="&nbsp;"
        prop="room_width"
        >
          <el-input
          v-model="room.room_width" 
          :id="'room_width'+index_room"
          type="text"
          />
          </el-form-item>
        </el-col>
           <el-col :span="3">
        <el-form-item 
        label="&nbsp;"
        prop="room_height"
        >
          <el-input
          v-model="room.room_height" 
          :id="'room_height'+index_room"
          type="text"
          />
          </el-form-item>
        </el-col>
        <el-col :span="4">
        <el-form-item 
        label="&nbsp;" 
        prop="room_other_info"
        >
        <el-input
          v-model="room.room_other_info" 
          :id="'room_other_info'+index_room"
          type="text"
          />
        </el-form-item>        
        </el-col>
        <el-col :span="2">
          <el-form-item  label="&nbsp;">
        <el-button  size="large" type="primary" icon="el-icon-minus" @click="deleteroom(index)"></el-button>
          </el-form-item>
        </el-col>
        </el-row>
      </div>
               </div>
        </el-tab-pane>
        <el-tab-pane 
          label="Bathrooms Information"
          class="line-height-5"
        >
         <div class="panel panel-default">
        <el-row :gutter="20">
        <el-col :span="12" class="padding-t-b-20">
        <div class="align-left">
        <el-button  size="large" type="primary" icon="el-icon-plus" @click="addbaths" >
        </el-button>
        </div>
        </el-col>
        </el-row>
        <el-row :gutter="20">
        <el-col :span="6">
        <div class="append-col-name">Floor</div>
        </el-col>
        <el-col :span="6">
        <div  class="append-col-name">Ensuite</div>
        </el-col>
         <el-col :span="6">
        <div  class="append-col-name">Pieces</div>
        </el-col>
        <el-col :span="4">
        <div  class="append-col-name">Other</div>
        </el-col>
        <el-col :span="2">
        <div  class="append-col-name">Action</div>
        </el-col>
        </el-row>  

        <div v-for="(bath, index_bath) in array_bath" :key="index_bath">
     
        <el-row :gutter="20" class="row-border-top">
         <el-col :span="6">
          <el-form-item 
          label="&nbsp;"
          prop="bathrooms_floor"
          >
          <el-select v-model="bath.bathrooms_floor"
          :id="'bathrooms_floor'+index_bath"
        >
          <el-option 
          v-for="item in array_roomfloor" 
          :key="item.label"
          :value="item.value"
          :label="item.label"           
          >
          {{ item.label }}
          </el-option>
        </el-select>
        </el-form-item>
        </el-col>
        <el-col :span="6">
        <el-form-item 
        label="&nbsp;" 
        prop="bathrooms_ensuite"
        >
         <el-select v-model="bath.bathrooms_ensuite"
          :id="'bathrooms_ensuite'+index_bath"
        >
          <el-option 
          v-for="item in array_ensuite" 
          :key="item.label"
          :value="item.value"
          :label="item.label"           
          >
          {{ item.label }}
          </el-option>
        </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
        <el-form-item 
        label="&nbsp;"
        prop="bathrooms_pieces"
        >

         <el-select v-model="bath.bathrooms_pieces"
          :id="'bathrooms_pieces'+index_bath"
        >
          <el-option 
          v-for="item in array_pieces" 
          :key="item.label"
          :value="item.value"
          :label="item.label"           
          >
          {{ item.label }}
          </el-option>
        </el-select>
          </el-form-item>
        </el-col>
           <el-col :span="4">
        <el-form-item 
        label="&nbsp;"
        prop="bathrooms_other_info"
        >
          <el-input
          v-model="bath.bathrooms_other_info" 
          :id="'bathrooms_other_info'+index_bath"
          type="text"
          />
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <el-form-item  label="&nbsp;">
        <el-button  size="large" type="primary" icon="el-icon-minus" @click="deletebath(index)"></el-button>
          </el-form-item>
        </el-col>
        </el-row>
      </div>
        </div>
        </el-tab-pane>
        <el-tab-pane 
          label="Other Information"
        >
         <div class="panel panel-default">
        <el-row :gutter="20">
        <el-col :span="12" class="padding-t-b-20">
        <div class="align-left">
        <el-button  size="large" type="primary" icon="el-icon-plus" @click="addotherinfo" >
        </el-button>
        </div>
        </el-col>
        </el-row>
        <div v-for="(information, index_other) in otherinformation" :key="index_other">
        <el-row :gutter="20" class="row-border-top">
        <el-col :span="6">
        <el-form-item 
        label="&nbsp;"
        >
        <el-select v-model="information.other_information_name"
          :id="'other_information_name'+index_other"
        >
          <el-option 
          v-for="item in selectinformationType" 
          :key="item.label"
          :value="item.value"
          :label="item.label"           
          >
          {{ item.label }}
          </el-option>
        </el-select>
        </el-form-item>
        </el-col>
        <el-col :span="6">
        <el-form-item 
        label="&nbsp;"        >
        <el-input 
        v-model="information.other_information_key"  
        type="text" 
        :id="'other_information_key'+index_other"
        autocomplete="off"
        placeholder="Enter Feild Title"
        />
        </el-form-item>
        </el-col>
        <el-col :span="6">
        <el-form-item 
        label="&nbsp;"
        >
        <el-input 
        v-model="information.other_information_value"  
        :id="'other_information_value'+index_other"
        type="text" 
        autocomplete="off"
        placeholder="Enter Feild Value"
        />
        </el-form-item>
        
        </el-col>
        
        <el-col :span="6">
        <el-form-item 
        label="&nbsp;" 
        >
        <el-button size="small" type="warning" icon="el-icon-minus"  @click="deleteotherinfo(index)"></el-button>
        </el-form-item>
        </el-col>
        </el-row>
        </div>

        </div>
	
        </el-tab-pane>
        <el-tab-pane 
          label="Feature Sheet"
        >
        <div class="panel panel-default">
          <el-form-item 
           label="Upload Sheet" 
            prop="feature_sheet">
              <el-upload
                :action="imageUploadUrlPdf"
                :file-list="fileListPdf"
                :headers="imageUploadHeadersPdf"
                :limit="1"
                :on-error="handleImageUploadErrorPdf"
                :on-preview="handlePreviewPdf"
                :on-remove="handleRemovePdf"
                :on-success="handleImageUploadSuccessPdf"
                class="upload-demo"
                drag
                list-type="text"
                name="file"
              >
                <i class="el-icon-upload" />
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em>
              </div>
              <template #tip>
                <div class="el-upload__tip">
                  PDF File only
                </div>
              </template>
              </el-upload>
            </el-form-item>
	</div>
        </el-tab-pane>
         
      <el-form-item class="align-left"> 
        <input
          id="listingId" 
          v-model="listingId"
          name="listingId" 
          type="hidden"
        >
        <el-button 
          type="primary"
          @click="submitForm('ruleForm')"
        >
          Submit
        </el-button>
        <el-button @click="resetForm('ruleForm')">
          Reset
        </el-button>
      </el-form-item>
      </el-tabs>
    </el-form>
</template>
<script>
import { ref } from 'vue'
import { genFileId } from 'element-plus'
import ListingsService from "@/api/listings.service";
import authHeader from '@/api/auth-header';
import CountryService from "@/api/country.service";
import BuildingService from "@/api/building.service";
import CommunityService from "@/api/community.service";
import {ElUpload} from 'element-plus';
import { UploadInstance, UploadProps, UploadRawFile } from 'element-plus'

  export default {
    components: {ElUpload},
    data() {
      var checkName = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Listing Name.'));
        } else {
          callback();
        }
      };
      var checkDescription = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Listing Description.'));
        } else {
          callback();
        }
      };
      var checkSlug = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Slug.'));
        } else {
          callback();
        }
      };
      var checkMetatitle = (rule, value, callback) => {        
          callback();
      };
      return {
        upload: null,
        links:[],
        buildings:[],
        communites:[],
        showAlertAdded: false,
        showAlertSlugexit: false,
        labelPosition: 'top',
        listingId: this.$route.params.listingId,
        imageUploadUrl: process.env.VUE_APP_BASE_API,
        imageUploadHeaders: null,
        imageUploadHeadersPdf: null,
        countries: [],
        states:[],
        cities:[],
        selectstatus:[{
          value: 'Enabled',
          label: 'Enabled'
        }, {
          value: 'Disabled',
          label: 'Disabled'
        }],
        selectinformationType:[{
          value: 'maininfo',
          label: 'Listing Main Information'
        }, {
          value: 'addressinfo',
          label: 'Listing Address Information'
        }, {
          value: 'generalinfo',
          label: 'General Information'
        }, {
          value: 'additionallinfo',
          label: 'Additional Information'
        }, {
          value: 'amenitiesinfo',
          label: 'Amenities/Features'
        }],
        
        array_ribbon:[ {
          value: 'sale',
          label: 'For Sale'
        },{
          value: 'rent',
          label: 'For Rent'
        },{
          value: 'lease',
          label: 'For Lease'
        },{
          value: 'sold',
          label: 'For Sold'
        },{
          value: 'showhome',
          label: 'Show Home'
          
        }],
        array_rental_duration:[ {
          value: 'weekely',
          label: 'Weekely'
        },{
          value: 'monthly',
          label: 'Monthly'
        },{
          value: 'quarterly',
          label: 'Quarterly'
        },{
          value: 'yearly',
          label: 'Yearly'
        }],
        array_parking_reg:[{
          value: 'Common Property',
          label: 'Common Property'
        },{
          value: 'Freehold',
          label: 'Freehold'
        },{
          value: 'Lease',
          label: 'Lease'
        },{
          value: 'Strata',
          label: 'Strata'
        },{
          value: 'Other',
          label: 'Other'
        },{
          value: 'Double Attached',
          label: 'Double Attached'
        },{
          value: 'Triple Attached',
          label: 'Triple Attached'
        }],
        array_banner_text:[{
          value: '3 CAR GARAGE',
          label: '3 CAR GARAGE'
        },{
          value: 'COMING SOON',
          label: 'COMING SOON'
        },{
          value: 'FOREST LOT',
          label: 'FOREST LOT'
        },{
          value: 'LAKE LOT',
          label: 'LAKE LOT'
        },{
          value: 'MOVE IN READY',
          label: 'MOVE IN READY'
        },{
          value: 'OPEN HOUSE',
          label: 'OPEN HOUSE'
        },{
          value: 'PARK LOT',
          label: 'PARK LOT'
        },{
          value: 'SOLD',
          label: 'SOLD'
        },{
          value: 'WALKOUT BASEMENT',
          label: 'WALKOUT BASEMENT'
        },{
          value: 'ASSIGNMENT OF LAND ASSEMBLY',
          label: 'ASSIGNMENT OF LAND ASSEMBLY'
        },{
          value: 'ASSIGNMENT OF CONTRACT',
          label: 'ASSIGNMENT OF CONTRACT'
        }],
        arrayamenities:[],
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        fileList: [],
        fileListMoreImages: [],
        array_roomtype:[], 
        array_roomfloor:[],
        array_flooring:[],
        array_site_influences:[],
        array_listing_goods:[],
        array_ensuite:[{
          value: 'Yes',
          label: 'Yes'
        }, {
          value: 'No',
          label: 'No'
        }],
        array_pieces:[{
          value: '1',
          label: '1'
        }, {
          value: '2',
          label: '2'
        }, {
          value: '3',
          label: '3'
        }, {
          value: '4',
          label: '4'
        }, {
          value: '5',
          label: '5'
        }] ,
        array_openhouse: [{open_house_date:'',open_house_endtime:'',open_house_starttime:''}],
        array_link:[{link_url:'',link_title:'',link_description:''}],
        array_bath:[{bathrooms_floor:'',bathrooms_ensuite:'',bathrooms_pieces:'',bathrooms_other_info:''}],
        array_room:[{room_floor:'',room_type:'',room_width:'',room_height:'',room_other_info:''}],        
        otherinformation:[{other_information_name:'',other_information_key:'',other_information_value:''}],
        array_foundation:[{
          value: 'Concrete',
          label: 'Concrete'
        }, {
          value: 'Piled',
          label: 'Piled'
        }],
        array_basement_dev:[],
        array_basement_type:[],
        fileListPdf: [],
        data: { 
        feature_sheet: null,    
        amenities:[], 
        flooring:[],
        listing_goods:[],
        site_influences:[],
        basement_dev:[],
        basement_type:[],
        openhouses:[], 
        links:[],    
        bath_rooms:[], 
        roomsinfo:[],
        otherinfo:[],
        listing_type: null,
        status: null,
        building_id: null,
        community_id: null,
        community_name:null,
        mlslisting_id: null,
        sold_date: null,
        ribbon: null,
        available_date: null,
        assignment: null,
        teamlisting: null,
        featuredlisting: null,
        is_show_home: null,
        is_ready_to_move: null,
        listingofthemonth: null,
        offer_pending: null,
        is_exclusive: null,
        suite_no: null,
        street_number: null,
        street_dir: null,
        street_name: null,
        street_type: null,
        country: null,
        country_name: null,
        building_name:null,
        state_name:null,
        state:null,
        city_name:null,
        city: null,
        postalcode: null,
        lat: null,
        lng: null,
        list_price: null,
        price_on_request: null,
        rental_duration: null,
        tax_amount:null,
        expires_date:null,
        completion_date:null,
        list_date:null,
        finished_levels:null,
        lot_size:null,
        SizeInterior:null,
        bedrooms:null,
        bathrooms:null,
        bathroom_partial:null,
        total_rooms:null,
        flex_rooms:null,
        kitchens:null,
        year_built:null,
        exposure:null,
        frontage:null,
        depth:null,
        home_style:null,
        outdoor_area:null,
        bylaw_restrictions:null,
        legal_description:null,
        age_description:null,
        sale_info:null,
        show_homes:null,
        construction:null,
        foundation:[],
        roof:null,
        water_supply:null,
        exterior_finish:null,
        title_to_land:null,
        sewer:null,
        maintenance_fee:null,
        units_in_development:null,
        occupancy:null,
        school_info:null,
        school_tax:null,
        mortgage_info:null,
        listing_note:null,
        internal_notes:null,
        listing_description:null,
        custom_listing_description:null,
        logo_image:null,
        meta_title:null,
        meta_description:null,
        social_posttitle:null,
        social_hashtag:null,
        post_listing:null,
        listingId:null,
        myResult:[],

        },
        // ruleForm: {
        //   name: '',
        //   image: ''
        // },
        rules: {
          street_name: [
            { validator: checkName, trigger: 'blur' }
          ],
          listing_description: [
            { validator: checkDescription, trigger: 'blur' }
          ],
         
          slug: [
            { validator: checkSlug, trigger: 'blur' }
          ]
        }
      };
    },
    watch: {
    data: function () {
    if(!this.data.country_name && this.data.country) {
      this.data.country_name = this.extractNameById(this.data.country, this.countries);
    }
    if(!this.data.state_name && this.data.state) {
      this.data.state_name = this.extractNameById(this.data.state, this.states);
    }
    if(!this.data.city_name && this.data.city) {
      this.data.city_name = this.extractNameById(this.data.city, this.cities);
    }
    if(!this.data.building_name && this.data.building_id) {
      this.data.building_name = this.extractNameById(this.data.building_id, this.buildings);
    }
    if(!this.data.community_name && this.data.community_id) {
      this.data.community_name = this.extractNameById(this.data.community_id, this.communites);
    }
    },
    countries: function () {
      this.data.country_name = this.extractNameById(this.data.country, this.countries);
    }
    ,states: function () {
      this.data.state_name = this.extractNameById(this.data.state, this.states);
    },
    cities: function () {
      this.data.city_name = this.extractNameById(this.data.city, this.cities);
    },
    buildings: function () {
      this.data.building_name = this.extractNameById(this.data.building_id, this.buildings);
    },
    communites: function () {
      this.data.community_name = this.extractNameById(this.data.community_id, this.communites);
    }
  },
	mounted() {
    this.upload = this.$refs.upload
    this.getcountries();
    this.getAmenities();
    this.getRoomtype();
    this.getRoomfloor();
    this.getFlooring();
    this.getInfluences();
    this.getListingsgoods();
    this.getBasementdev();
    this.getBasementtype();
    this.getbuildinglist();
    this.getcommunitylist();
    this.setImageUploadUrl();
    this.setImageUploadUrlPdf();
    if(this.listingId){
      this.getlisting();
    }
  },
    methods: {  
       setImageUploadUrlPdf(){
        this.imageUploadHeadersPdf = authHeader();
        this.imageUploadUrlPdf = process.env.VUE_APP_BASE_API + 'image-upload-pdf';
    },
      handleImageUploadSuccessPdf(response, file, fileListPdf) {
        if(response.code == 200) {
          this.data.feature_sheet = response.image_url;
        }
      },
      handleImageUploadErrorPdf(err, file, fileListPdf) {
        console.log(err)
      },
      handlePreviewPdf(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemovePdf(file, fileListPdf) {
        console.log(file, fileListPdf)
        //TODO: Delete the file
      },  
    addotherinfo: function () {        
        this.otherinformation.push({other_information_name:'',other_information_key:'',other_information_value:''});
     },

      deleteotherinfo: function (index) {
        this.otherinformation.splice(index, 1);
        if(index===0)
          this.addotherinfo()
      },
      
      addrooms: function () {
        this.array_room.push({room_floor:'',room_type:'',room_width:'',room_height:'',room_other_info:''});
     },

      deleteroom: function (index) {
        this.array_room.splice(index, 1);
        if(index===0)
          this.addrooms()
      },

    addbaths: function () {
        this.array_bath.push({bathrooms_floor:'',bathrooms_ensuite:'',bathrooms_pieces:'',bathrooms_other_info:''});
     },
      deletebath: function (index) {
        this.array_bath.splice(index, 1);
        if(index===0)
          this.addbaths()
      },

      addlinks: function () {
        this.array_link.push({link_url:'',link_title:'',link_description:''});
     },

      deletelink: function (index) {
        this.array_link.splice(index, 1);
        if(index===0)
          this.addlinks()
      },

      addopenhouse: function () {
        this.array_openhouse.push({open_house_date:'',open_house_endtime:'',open_house_starttime:''});
     },
      deleteopenhouse: function (index) {
        this.array_openhouse.splice(index, 1);
        if(index===0)
          this.addopenhouse()
      },
      async getbuildinglist(){
      return BuildingService.getbuildinglist().then(response => {
        this.buildings=response.data.data;
      });
      },
      async getcommunitylist(){
      return CommunityService.getcommunitylist().then(response => {
        this.communites=response.data.data;
      });
      },
      async getCities(statsid){
      return CountryService.getCities(1,statsid).then(response => {
      this.cities=response.data.data;
      });
      },
      async getStates(countryid){
      return CountryService.getStates(1,countryid).then(response => {
      this.states=response.data.data;
      });
      },
      async getcountries(){
      return CountryService.getcountries().then(response => {
      this.countries=response.data.data;
      });
      },
      async getAmenities(){
      return CountryService.getAmenities().then(response => {
      this.arrayamenities=response.data.data;
      });
      },
      async getRoomtype(){
      return CountryService.getRoomtype().then(response => {
      this.array_roomtype=response.data.data;
      });
      },
      async getRoomfloor(){
      return CountryService.getRoomfloor().then(response => {
      this.array_roomfloor=response.data.data;
      });
      },
      async getFlooring(){
      return CountryService.getFlooring().then(response => {
      this.array_flooring=response.data.data;
      });
      },
      async getInfluences(){
      return CountryService.getInfluences().then(response => {
      this.array_site_influences=response.data.data;
      });
      },
      async getListingsgoods(){
      return CountryService.getListingsgoods().then(response => {
      this.array_listing_goods=response.data.data;
      });
      },
       async getBasementdev(){
      return CountryService.getBasementdev().then(response => {
      this.array_basement_dev=response.data.data;
      });
      },
       async getBasementtype(){
      return CountryService.getBasementtype().then(response => {
      this.array_basement_type=response.data.data;
      });
      },
      
      extractNameById(needle, hayStack) {
      let val = null;
      val = hayStack.filter(function(element){
      if(element['value'] == Number(needle)) {
      return element['label'];
      }
      });
      if(val.length) {
      return val[0]['label'];
      }
      return null;
      },
    
      submitForm(formName) {
        this.data.country = this.data.country_name;
        this.data.building_id = this.data.building_name;
        this.data.community_id = this.data.community_name;
        this.data.state = this.data.state_name;
        this.data.city = this.data.city_name; 
        this.data.openhouses=this.array_openhouse;
        this.data.links=this.array_link;
        this.data.bath_rooms=this.array_bath; 
        this.data.roomsinfo=this.array_room;   
        this.data.otherinfo=this.otherinformation; 
        this.data.more_images=this.fileListMoreImages; 
              
        this.$refs[formName].validate((valid) => {
          if (valid && !this.listingId) {
             return ListingsService.add(this.data).then(response => {
                this.showAlertAdded=true 
                return response
              });
          } else if (valid && this.listingId) {
            return ListingsService.update(this.listingId, this.data).then(response => {
              this.showAlertAdded=true;
              return response;

            });
          }else {
			this.showAlertSlugexit=true
            console.log('error submit!!');
            return false;
          }
        });
      },handleFileUpload(){

      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
	listings(){
		this.$router.push("/listings");
	},
	getlisting(){
    return ListingsService.get(this.listingId).then(response => {
        this.data = response.data.data;
         if(response.data.data.array_link!=''){
          this.array_link =response.data.data.array_link;
        }

        if(response.data.data.array_room!=''){
          this.array_room =response.data.data.array_room;
        }
         if(response.data.data.array_bath!=''){
          this.array_bath =response.data.data.array_bath;
        }
        if(response.data.data.otherinformation!=''){
          this.otherinformation =response.data.data.otherinformation;
        }
         if(response.data.data.array_openhouse!=''){
          this.array_openhouse =response.data.data.array_openhouse;
        }
        if(response.data.data.moreimages!=''){
          this.fileListMoreImages =response.data.data.moreimages;
        }
      
        //this.array_openhouse =[{open_house_date: "2021-10-14",open_house_endtime: "2021-10-14 06:29",open_house_starttime: "2021-10-14 01:30"},{open_house_date: "2021-10-15",open_house_endtime: "2021-10-15 02:27",open_house_starttime: "2021-10-15 02:27"}]
        if(this.data.country){
          this.getStates(this.data.country);
        }
        if(this.data.state){
          this.getCities(this.data.state);
        }    
        this.fileList.push({name:'', url: response.data.data.image});
        this.fileListPdf.push({name:'', url: response.data.data.feature_sheet_url});
        return response;
      });
	},
      setImageUploadUrl() {
        this.imageUploadHeaders = authHeader();
        this.imageUploadUrl = process.env.VUE_APP_BASE_API + 'image-upload';
      },
      handleExceed(files) {
        this.upload.clearFiles()
        const file = files[0]// as UploadRawFile
        file.uid = genFileId()
        this.upload.handleStart(file)
      },
      handleImageUploadSuccess(response) {
        if(response.code == 200) {
          this.fileList = []
          this.data.logo_image = response.image_url;
          this.fileList.push(this.data.logo_image)
        }
      },
      handleImageUploadError(err) {
        console.log(err)
      },
      handlePreview(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemove(file, fileList) {
        console.log(file, fileList)
        //TODO: Delete the file
      },
      handleImageUploadSuccessMoreImages(response) {
        if(response.code == 200) {
			this.fileListMoreImages.push(response.image_url);
        }
      },
      handleImageUploadErrorMoreImages(err) {
        console.log(err)
      },
      handlePreviewMoreImages(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemoveMoreImages(file, fileListMoreImages) {
        console.log(file, fileListMoreImages)
        //TODO: Delete the file
      },

    
    }
  }
</script>
<style lang="scss" scoped>
.panel {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 17px;
    display: inline-block;
    background: #fff;
    border: 1px solid #E6E9ED;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    opacity: 1;
    transition: all .2s ease;
}
.el-row {
    margin-bottom: 0px !important;
}
.el-form--label-top .el-form-item__label{
      float: left !important;
      font-weight: 500;
      font-size: 12px;
      padding: 0 0 0px 0;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }  
  .heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
    .login {
        flex: 1;
		width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   .login-form {
        width: 100%;
    }
    .selectstatus{
      -webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    }
div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
.el-select{
     width: 100%;
}
div .topsearch .el-input{
  padding-right:10px;
}
.pagination{ 
  padding: 12px 5px;
  float: right;
}.align-left{text-align:left;}
.el-checkbox {    width: 25%;
    display: inline-block;
    float: left;margin-right:0px;}
 .selectmultiple2{-webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 80px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;}
</style>
<style lang="scss" >
el-fieldset {float: left;padding-bottom:10px;padding: 18px 0px;font-size: 18px;font-weight: 500;}
.el-radio-group {
    float: left;
}
.el-checkbox {
    width: 25% !important;
    text-align: left;
}
.el-checkbox__input.is-checked + .el-checkbox__label{color:#606266 !important;}
.el-date-editor.el-input, .el-date-editor.el-input__inner{width:100%}
.display-none{display:none;}
.row-border-top{border-top: 1px solid #E6E9ED;}
.padding-t-b-20{padding: 20px 0px 20px 0px;}
.line-height-5 .el-form-item__label{line-height: 5px;}
.append-col-name{font-size: var(--el-form-label-font-size);color: var(--el-text-color-regular);font-weight: 700;}
#moreimg-layout ul{display: inline-flex;gap: 20px;flex-wrap: wrap;}
#moreimg-layout ul li{width:200px;}
#moreimg-layout .el-upload-dragger{width:418px;}
.upload-demo{ display: flex;gap: 20px;width: 100%;max-width:100%;}
@media screen and (max-width: 992px) {
#moreimg-layout.upload-demo{ display: unset; gap: unset;width: unset;max-width:unset;}
}
@media screen and (max-width: 767px) {
.upload-demo{ display: unset; gap: unset;width: unset;max-width:unset;}
}
</style>
